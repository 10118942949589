.survey-form-container {
  font-family: Arial, sans-serif;
  background-color: #f4f7fc;
}

.header {
  background: linear-gradient(90deg, rgba(2,180,228,1) 18%, rgba(8,138,175,1) 47%, rgba(28,0,3,1) 100%);  color: white;
}

.header-image {
  width: 100%;
  max-width: 550px;
  height: 240px;
}

.header-title {
  font-size: 1.2rem;
}

.container {
  max-width: 600px;
  margin: 0 auto;
}

.form-group {
  margin-bottom: 20px;
}

.form-check {
  margin-bottom: 10px;
}

.btn {
  width: 100%;
  padding: 10px;
}

.btn-primary {
  background-color: #007bff;
  border-color: #007bff;
}

.btn-primary:hover {
  background-color: #0056b3;
}

.btn-secondary {
  background-color: #6c757d;
  border-color: #044c8a;
}

.btn-secondary:hover {
  background-color: #5a6268;
}

/* Make the form responsive */
@media (max-width: 576px) {
  .header-title {
    font-size: 1rem;
  }

  .container {
    padding: 0 15px;
  }

  .form-group {
    margin-bottom: 15px;
  }

  .btn {
    width: 100%;
    font-size: 14px;
  }
}
.social-share-buttons .btn {
  margin: 0 10px;
}

.social-share-buttons .btn-facebook {
  background-color: #3b5998;
  color: white;
}

.social-share-buttons .btn-instagram {
  background-color: #e4405f;
  color: white;
}

.social-share-buttons .btn-x {
  background-color: #1da1f2;
  color: white;
}

.social-share-buttons .btn-telegram {
  background-color: #0088cc;
  color: white;
}

.social-share-buttons .btn:hover {
  opacity: 0.8;
}
.form-label {
  font-size: 18px;
  font-weight: bold;
}

.form-check {
  font-size: 16px;
}

.btn {
  font-size: 16px;
}

.social-icons {
  display: flex;
  gap: 15px;
}

.social-icons button {
  display: flex;
  align-items: center;
  padding: 8px 15px;
  font-size: 16px;
  transition: background-color 0.3s, transform 0.3s;
}

.social-icons button:hover {
  transform: scale(1.05);
}

.social-icons i {
  margin-right: 5px;
}

.btn-outline-primary {
  border-color: #007bff;
  color: #007bff;
  background-color: transparent;
}

.btn-outline-primary:hover {
  background-color: #007bff;
  color: white;
}

.shadow {
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
}

.rounded {
  border-radius: 8px;
}